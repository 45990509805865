<template>
  <Teleport to="#checkout">
    <div :id="containerId" />
  </Teleport>
</template>

<script setup lang="ts">
import type { PaypalPaymentMethod } from '#gql/default'

interface Props {
  formId: string
  paymentMethod: PaypalPaymentMethod
}

const { paymentMethod } = defineProps<Props>()
const { init } = await usePayWithPaypal()
const containerId = useId()

onMounted(() => {
  init({
    clientId: paymentMethod.clientId,
    containerId: `#${containerId}`,
  })
})

defineOptions({
  name: 'PaymentTypePaypal',
})
</script>
