import { default as _91_46_46_46pageSlug_93fUbX77VppNMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateaiKOBdwS1sMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue?macro=true";
import { default as indexQ3hy2o8VDSMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93CVFNdndvIvMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newdrVPPjBB6kMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistpo5a2hw5fyMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexhChtvZBlnQMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue?macro=true";
import { default as editYNOdF6SppgMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexMVGWsUF3WmMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addJtGCT1Aal0Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesaZ9jMX2k4sMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqG1NKKzarDlMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as info2aCzDcX1O3Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93EkzoRPl5y3Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programJt7rjGB754Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopbs4ZDhgiPpMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93uyO424rPerMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_93KBNK4S5B0hMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchers7af2UJbw6pMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93dQBqiL8eNxMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexuHcLPCf767Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93eRvb6d4M24Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasj0BvdN1YLAMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesA9RzhcJ26tMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue?macro=true";
import { default as index603tYpY2H1Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_93Ky9hLUrTt3Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexSAonm4qJUPMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93HZYjILkDP1Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresfRhzsBdDZMMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexrFydip3v3fMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexzhMAZ8SfjpMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93PdOnIZzfBuMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as persons4XMMqLylCOMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue?macro=true";
import { default as searchFSEYY3KyS7Meta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue?macro=true";
import { default as vouchersOeX9PieyuDMeta } from "/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexhChtvZBlnQMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editYNOdF6SppgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexMVGWsUF3WmMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addJtGCT1Aal0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesaZ9jMX2k4sMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93eRvb6d4M24Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93HZYjILkDP1Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93PdOnIZzfBuMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/live/2025-01-22_11-28-32_f3b1fec/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]