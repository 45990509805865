import { loadScript } from '@paypal/paypal-js'
import type { Cart } from '#gql/default'

export default async function usePayWithPaypal() {
  const { cart, checkoutCart } = await useCart()
  const { displayError } = usePaymentMethod()

  async function pay(orderId: string) {
    const result = await GqlCartPayWithPaypal({
      cartId: cart.value!.id,
      orderId,
    })

    cart.value = result.payWithPaypal as Cart
  }

  async function init({
    clientId,
    containerId,
  }: {
    clientId: string
    containerId: string
  }) {
    let paypal

    try {
      paypal = await loadScript({
        clientId,
        currency: 'EUR',
        components: ['buttons'],
      })
    } catch (error) {
      displayError({ message: 'failed to load the PayPal JS SDK script' })
      return
    }

    try {
      await paypal
        .Buttons({
          fundingSource: paypal?.FUNDING?.PAYPAL,
          style: {
            layout: 'vertical',
            color: 'gold',
            shape: 'pill',
            label: 'paypal',
            height: 40,
          },
          createOrder(_, actions) {
            return actions.order.create({
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    value: cart.value!.price.total.toFixed(2),
                    currency_code: 'EUR',
                  },
                },
              ],
            })
          },
          async onApprove({ orderID: orderId }) {
            try {
              await pay(orderId)
              await checkoutCart()
            } catch (err) {
              const errorMessage =
                err instanceof Error ? err.message : 'Unknown error occurred'
              displayError({
                message: `Payment failed: ${errorMessage}`,
              })
            }
          },
        })
        .render(containerId)
    } catch (error) {
      displayError({
        message: 'failed to render the PayPal Buttons',
      })
    }
  }

  return {
    pay,
    init,
  }
}
